div {
  box-sizing: border-box;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
}
body.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}


@media (max-width: 832px) {
  .ReactModal__Content {
    z-index: 15;
    /*margin: 50px auto;*/
    background-color: white;
    border: 1px solid lightgrey;
    /*inset: 20px 0;*/
    /*max-height: 80vh;*/
    overflow-y: scroll;
    position: fixed;
    top: 30px;
    bottom: 30px;
    left: 0;
    right: 0;
  }
}
@media (min-width: 832px) {
  .ReactModal__Content {
    margin: 20px auto;
    z-index: 15;
    position: fixed;
    top: 20px;
    left: 20%;
    right: 20%;
    background-color: white;
    border: 1px solid lightgrey;
    min-height: 500px;
    max-height: 80vh;
    overflow-y: scroll;
    max-width: 800px;
  }
}

.sortable-container {
  z-index: 12;
}
